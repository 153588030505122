<!--
 * @Author: cuixuecheng
 * @Date: 2024-06-13 10:09:13
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2025-02-26 12:43:22
 * @Description: 
-->
<template>
  <div class="wrap">
    <div id="quick" class="quick"></div>
    <!-- <div class="line"></div>
    <button class="dialout" @click="dialout">外呼</button> -->
  </div>
</template>

<script>

import quickSDK from 'sphone-q-sdk';
import { Message } from 'element-ui'

export default {
  name: 'QuickCom',
  components:{  },
  data(){
    return {
      loginSuccess: false,
      loginInfo: {},
      sdkInstance: null
    }
  },
  methods:{
    handleLogin(res) {
      console.log(res)
      if (res) {
        this.loginSuccess = true
        this.loginInfo = res.data
      } else {
        this.loginSuccess = false
      } 
    },
    dialout() {
      this.instance.dialout({
        success: function() {
          console.log('123312313132121')
        },
        error: function(err) {
          console.log(err)
        },
      })
    },
    init(username, password) {
      this.instance = new quickSDK({
        mountId: 'quick',
        config: { 
          // server: 'https://kf.dev1.xinmuai.net/api',
          username: username, 
          password: password
        } 
      });
      this.instance.init();
    }
  },
  mounted() {
    const search = window.location.search;
    const queryString = search.split('?')[1];
    if (!queryString) {
      Message({
        message: '请在url中拼接?username=xxx&password=xxx',
        type: 'error',
        duration: 5 * 1000
      })
      return;
    }
    const urlParams = new URLSearchParams(queryString);
    const username = urlParams.get('username');
    const password = urlParams.get('password');
    console.log(urlParams, username, password)
    this.init(username, password)
  }
}
</script>

<style>
.quick{
  position: relative;
  z-index: 999;
}
.line{
  background: #eee;
  height: 10px;
}
</style>
