/*
 * @Author: cuixuecheng
 * @Date: 2023-10-25 10:24:39
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2025-02-26 12:21:21
 * @Description: 
 */
import Vue from 'vue';
import App from './App.vue';
import Demo from './Demo.vue';
import Quick from './Quick.vue';
import QuickDev from './QuickDev.vue';
import Router from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Router);

const routes = [
  { path: '/', name: 'index', component: Demo },
  { path: '/demo', name: 'demo', component: Demo },
  { path: '/quick', name: 'quick', component: Quick },
  { path: '/quick-dev', name: 'quickDev', component: QuickDev },
];

const router = new Router({
  mode: 'history',
  routes,
});

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
  router,
  render(h) {
    const currentRoute = window.location.pathname
    const route = routes.find(route => route.path === currentRoute)
    const component = route && route.component || App
    return h(component);
  },
}).$mount('#app');
